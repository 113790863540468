import { NotificationModel } from '@index/models/notifications';
import { createAction, props, union } from '@ngrx/store';
import { GthNotificationModel } from '@sentinels/models';

export enum NotificationsActionTypes {
  NotificationAdd = '[Notifications] Add',
  NotificationAddSuccess = '[Notifications] Add Success',
  NotificationAddError = '[Notifications] Add Error',

  NotificationAddMany = '[Notifications] AddMany',
  NotificationAddManySuccess = '[Notifications] AddMany Success',
  NotificationAddManyError = '[Notifications] AddMany Error',

  NotificationRead = '[Notifications] Read',
  NotificationReadSuccess = '[Notifications] Read Success',
  NotificationReadError = '[Notifications] Read Error',

  NotificationReadAll = '[Notifications] Read All',
  NotificationReadAllSuccess = '[Notifications] Read All Success',
  NotificationReadAllError = '[Notifications] Read All Error',

  NotificationUnread = '[Notifications] Unread',
  NotificationUnreadSuccess = '[Notifications] Unread Success',
  NotificationUnreadError = '[Notifications] Unread Error',

  NotificationRemove = '[Notifications] Remove',
  NotificationRemoveSuccess = '[Notifications] Remove Success',
  NotificationRemoveError = '[Notifications] Remove Error',

  NotificationClear = '[Notifications] Clear',
  NotificationClearSuccess = '[Notifications] Clear Success',
  NotificationClearError = '[Notifications] Clear Error',

  NotificationLoad = '[Notifications] Load',
  NotificationLoadSuccess = '[Notifications] Load Success',
  NotificationLoadError = '[Notifications] Load Error',
};

export const notificationAdd = createAction(
  NotificationsActionTypes.NotificationAdd,
  props<{ userId: string, notification: NotificationModel }>(),
);
export const notificationAddSuccess = createAction(
  NotificationsActionTypes.NotificationAddSuccess,
  props<{ notification: NotificationModel }>(),
);
export const notificationAddError = createAction(
  NotificationsActionTypes.NotificationAddError,
  props<{ error: unknown }>(),
);


export const notificationAddMany = createAction(
  NotificationsActionTypes.NotificationAddMany,
  props<{ userIds: string[], notification: NotificationModel }>(),
);
export const notificationAddManySuccess = createAction(
  NotificationsActionTypes.NotificationAddManySuccess,
  props<{ userIds: string[] }>(),
);
export const notificationAddManyError = createAction(
  NotificationsActionTypes.NotificationAddManyError,
  props<{ error: unknown }>(),
);

export const notificationRead = createAction(
  NotificationsActionTypes.NotificationRead,
  props<{ userId: string, notificationId: string }>(),
);
export const notificationReadSuccess = createAction(
  NotificationsActionTypes.NotificationReadSuccess,
  props<{ notificationId: string }>(),
);
export const notificationReadError = createAction(
  NotificationsActionTypes.NotificationReadError,
  props<{ error: unknown }>(),
);

export const notificationReadAll = createAction(
  NotificationsActionTypes.NotificationReadAll,
  props<{ userId: string }>(),
);
export const notificationReadAllSuccess = createAction(
  NotificationsActionTypes.NotificationReadAllSuccess,
);
export const notificationReadAllError = createAction(
  NotificationsActionTypes.NotificationReadAllError,
  props<{ error: unknown }>(),
);

export const notificationUnread = createAction(
  NotificationsActionTypes.NotificationUnread,
  props<{ userId: string, notificationId: string }>(),
);
export const notificationUnreadSuccess = createAction(
  NotificationsActionTypes.NotificationUnreadSuccess,
  props<{ notificationId: string }>(),
);
export const notificationUnreadError = createAction(
  NotificationsActionTypes.NotificationUnreadError,
  props<{ error: unknown }>(),
);

export const notificationRemove = createAction(
  NotificationsActionTypes.NotificationRemove,
  props<{ userId: string, notificationId: string }>(),
);
export const notificationRemoveSuccess = createAction(
  NotificationsActionTypes.NotificationRemoveSuccess,
  props<{ id: string }>(),
);
export const notificationRemoveError = createAction(
  NotificationsActionTypes.NotificationRemoveError,
  props<{ error: unknown }>(),
);

export const notificationClear = createAction(
  NotificationsActionTypes.NotificationClear,
  props<{ userId: string }>(),
);
export const notificationClearSuccess = createAction(
  NotificationsActionTypes.NotificationClearSuccess,
);
export const notificationClearError = createAction(
  NotificationsActionTypes.NotificationClearError,
  props<{ error: unknown }>(),
);

export const notificationLoad = createAction(
  NotificationsActionTypes.NotificationLoad,
  props<{ userId: string }>(),
);
export const notificationLoadSuccess = createAction(
  NotificationsActionTypes.NotificationLoadSuccess,
  props<{ notifications: GthNotificationModel[] }>(),
);
export const notificationLoadError = createAction(
  NotificationsActionTypes.NotificationLoadError,
  props<{ error: unknown }>(),
);

export const allNotifications = union({
  notificationAdd,
  notificationAddSuccess,
  notificationAddError,
  notificationRemove,
  notificationRemoveSuccess,
  notificationRemoveError,
  notificationClear,
  notificationClearSuccess,
  notificationClearError,
  notificationLoad,
  notificationLoadSuccess,
  notificationLoadError,
});

export type NotificationsActionsUnion = typeof allNotifications;
